<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>校区实况</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">实况</span>
          <live v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">回放</span>
          <recall v-if="activeKey==='2'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import live from './live'
import recall from './recall'
export default {
  components: {
    live,
    recall
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

