<template>
    <div>
        <div class="clearfix table-tools">
            <div class="search">
             <a-form layout='inline' @submit="searchList">
                <a-form-item class="block-line" >
                <a-select allowClear v-model="searchParams.search.studio_id" placeholder="请选择校区" showSearch :filterOption="filterOption" style="width:220px;">
                    <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                </a-select>
                </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <template slot="title">
                        <span>时间筛选</span>
                    </template>
                    <a-range-picker
                        style="width: 240px"
                        :ranges="rangesData"
                        :value='rangesValue'
                        :allowClear="false"
                        :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]" 
                        @change="(date, dateString)=>onChange(date, dateString,2)" />
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
                </a-form-item>
            </a-form>
            </div>
        </div>
        <div class="common zlgx">
            <div class="c-side">
                <div class="list">
                    <perfect-scrollbar class="m-scrollbar" v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="busy"
                :infinite-scroll-distance="10">
                        <div class="item material-btns" :class="{ 'active': current.Id === item.Id }"  v-for="(item, index) of list" :key="index" @click="clickRecord(item)">
                            <div class="name">[{{ item.Name }}] {{ item.RecordTime }}</div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <LEmpty v-if="list.length==0"/>
                <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
            </div>
            <div class="c-main">
            <LEmpty v-if="!current.id"/>
            <div class="zl-list" style="width:80%">
                <div v-if="current" class="title">
                    <h3>{{current.RecordTime}}</h3>
                </div>
                <div>
                    <div id="dplayer"></div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DPlayer from 'dplayer';
import Hls from 'hls.js'
import url from '@/utils/URL'
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'
import infiniteScroll from 'vue-infinite-scroll'

export default {
    name:'recall',
    components: {
    },
    provide() {
        return {
            parent: this
        }
    },
    mixins: [ ranges, tableMixins ],
    directives: { infiniteScroll },
    created(){
        this.searchData.start_date =  moment().add(-1,'week').format('YYYY-MM-DD')
        this.searchData.end_date =moment().format('YYYY-MM-DD')
        if(this.$route.query.start_date){
            this.rangesValue = [moment(this.$route.query.start_date,'YYYY-MM-DD'),moment(this.$route.query.end_date,'YYYY-MM-DD')]
            this.searchData.start_date = moment(this.$route.query.start_date).format('YYYY-MM-DD')
            this.searchData.end_date = moment(this.$route.query.end_date).format('YYYY-MM-DD')
        }else{
            this.rangesValue = [moment(this.searchData.start_date,'YYYY-MM-DD'),moment(this.searchData.end_date,'YYYY-MM-DD')]
        }
        this.getStudio()
        // this.getList();
    },
    mounted(){  
        document.querySelector("#dplayer").oncontextmenu =  () => {
            document.querySelector(".dplayer-menu").style.display = "none";
            document.querySelector(".dplayer-mask").style.display = "none";
            return false;
        };
    },
    data() {
        return {
            activeId:false,
            visible:false,
            highlight:[],
            modalData:false,
            player:false,
            busy: false,
            current:false,
            loading: false,
            rangesValue:[],
            studios:[],
            list: [],
            searchParams: {
                "page": 1,
                "per-page": 30,
                "search": {},
                "sort": ''
            },
            paramsData:{},
            searchData: {},
            uploadUrl: url.uploadQiNiu, 
            uploadParams: {},
            currentCategory: {},
            selectedKeys:[],
            selectedKeysF:[],
            uploadIndex:0,
            uploadIndexF:0,
            fileList:[],
            showTree:false,
        }
    },
    methods: {
        clickRecord(item){
            this.activeId=item.Id
            this.current=item;
            this.player.switchVideo({
                url:item.OssObject,
                type: 'customHls',
                customType: {
                    customHls: function (video, player) {
                        const hls = new Hls();
                        hls.loadSource(video.src);
                        hls.attachMedia(video);
                        player.events.on('destroy', () => {
                            hls.stopLoad();
                            hls.destroy();
                        });
                    },
                },
            })
        },
        async play(item){
            this.highlight = [];
            this.$nextTick(() => {
                let playerOtions =  {
                    container: document.getElementById('dplayer'),
                    autoplay:false,
                    live: false,
                    volume:0,
                    muted: true,
                    screenshot:true,
                    preload: 'auto',
                    contextmenu:[],
                    highlight:this.highlight,
                    video: {
                        url: item.OssObject,
                        type: 'customHls',
                        customType: {
                            customHls: function (video, player) {
                                const hls = new Hls();
                                hls.loadSource(video.src);
                                hls.attachMedia(video);
                                player.events.on('destroy', () => {
                                    hls.stopLoad();
                                    hls.destroy();
                                });
                            },
                        },
                    }
                }
                this.player = new DPlayer(playerOtions)
            })
        },
        async getStudio() {
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
        },
        handleInfiniteOnLoad(){
            if(!this.busy){
                this.searchParams.page++
                this.getList()
            }
        },
        async getList() {
            this.busy=true;
            this.loading = true
            this.searchParams.search.start_date = this.$route.query.start_date || this.paramsData.start_date || moment().add(-1,'week').format('YYYY-MM-DD')
            this.searchParams.search.end_date = this.$route.query.end_date || this.paramsData.end_date ||  moment().format('YYYY-MM-DD')
            this.searchParams['per-page'] = 10;
            // console.log(this.searchParams);
            let res = await this.$store.dispatch('monitorRecallIndexAction', this.searchParams)
            if(this.searchParams.page == 1){
                this.busy = false
                this.list=[];
                if(this.player){
                    this.player.destroy();
                    this.player=false;
                }
                if(res.items.length>0){
                    this.current= res.items[0]
                    this.play(this.current)
                }
            }else{
               this.busy = false 
            }
            if(res.items.length >0){
                this.list = [...this.list,...res.items]
            }
            if(res._meta.totalCount == this.list.length){
                this.busy = true
            }
            this.pageParams = res._meta
            // console.log('loaded');
            this.loading = false
        },
    },
}
</script>
<style lang="scss" scoped>
// .m-scrollbar {
  
// }
.list{
    height: calc(100vh - 280px);
    overflow-y: auto;
}
.material-left-nav{
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.material-btns{
  display: flex;
  align-items: center;
  &-icon{
      position: absolute;
      right: 10px;
      cursor: pointer;
  }
  &-count{
      position: absolute;
      right: 25px;
      font-size: 12px;
      color: #999;
  }
}
</style>