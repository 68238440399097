<template>
  <div>
      <div class="clearfix table-tools">

        <!-- <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增实况</a-button>
            </a-form-item>
          </a-form>
        </div> -->

        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item class="block-line" >
              <a-select allowClear v-model="searchData.studio_id" placeholder="请选择校区" showSearch :filterOption="filterOption" style="width:220px;">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      
      <!-- <div class="table">
        <div :id="`dplayer_${item.monitor_id}`" v-for="(item,index) in list" style="width:300px;"></div>
      </div> -->
      
      
      <div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>  
        <div class="table">
            <div style="background-color: #ececec; padding: 20px;"  v-if="list.length>0" >
              <a-row :gutter="[24,8]" type="flex" justify="start">
                <a-col :span="8" v-for="(item,index) in list" :key="index">
                  <a-card hoverable :title="`${item.studio_name} - ${item.room_name}`" :bodyStyle="bodyStyle">
                    <div :id="`dplayer_${item.monitor_id}`"></div>
                    <!-- <template slot="actions" class="ant-card-actions">
                      <a-icon key="copy" type="copy" v-clipboard:copy="item.monitor_publish_url" v-clipboard:success="onCopy" v-clipboard:error="onError" />
                      <a-icon key="sync" type="sync" @click="clickRereash(item)" />
                      <a-icon key="edit" type="edit" @click="showEditModal(item)" />
                    </template> -->
                  </a-card>
                </a-col>
              </a-row>
              
            </div>
            
        </div>
        <!-- <LEmpty v-if="list.length==0"/>   -->
      </div>
      
    
    <editModal v-if="editVisible" :item="modalData"/>
    <div @click="handleDetailOk" v-if="detail_visible" class="message-bg"></div>
  </div>
</template>

<script>
import DPlayer from 'dplayer';
import flvjs from 'flv.js'
import Hls from 'hls.js'
import editModal from './editModal'
import ranges from "@/common/mixins/ranges";
import authority from '@/common/mixins/authority'
import moment from 'moment'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'live',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      bodyStyle:{
        padding:'0px'
      },
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      rangesData:[],
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      dp:[],
      studios: [],
      playerOtions: [],
      searchData: {},
      visible: false,
      modalData: {},
      editVisible: false,
      listVisible: false,
      detail_visible:false,
      authType:['orginization','notification'],
    }
  },
  mixins: [ ranges, authority, tableMixins ],
  created() {
    this.getStudio()
  },
  mounted() {
  },
  beforeDestroy() {
    this.dp.map(dp=>{
      if(dp)
        dp.destroy();
    })
    // console.log(this.dp)
    // console.log('destroy');
  },
  methods: {
    onCopy(){
        this.$message.success('已复制到剪切板!')
    },
    onError(){
        this.$message.error('操作失败!')
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    getPlay(){
      return ;
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async clickRereash(item) {
      await this.$store.dispatch('monitorRefreashAction',{monitor_id:item.monitor_id}).then(res => {
        console.log(res);    
      }).catch(reason => {
          console.log(reason)
      })
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'dateline') {
            obj['start_date'] = moment(searchData[k][0]).format('YYYY-MM-DD')
            obj['end_date'] = moment(searchData[k][1]).format('YYYY-MM-DD')
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('monitorIndexAction', this.searchParams)
      this.list = res.items

      this.$nextTick(() => {
        this.list.map(item=>{
            let option = {
              container: document.getElementById('dplayer_'+item.monitor_id),
              mutex:false,
              volume:0,
              preload:'none',
              autoplay:false,
              screenshot: true,
              live: true,
              // video: {
              //     url: item.monitor_play_url,
              //     type: 'customFlv',
              //     customType: {
              //         customFlv: function (video, player) {
              //             const flvPlayer = flvjs.createPlayer({
              //                 type: 'flv',
              //                 url: video.src,
              //             });
              //             flvPlayer.attachMediaElement(video);
              //             flvPlayer.load();
              //             player.events.on('destroy', () => {
              //                 flvPlayer.pause()
              //                 flvPlayer.unload()
              //                 flvPlayer.detachMediaElement()
              //                 flvPlayer.destroy()
              //             });
              //         },
              //     },
              // },
              video: {
                  url: item.monitor_play_url,
                  type: 'customHls',
                  customType: {
                      customHls: function (video, player) {
                          const hls = new Hls({
                            p2pConfig: {
                              live: true, // 如果是直播设为true
                            },
                          });
                          hls.loadSource(video.src);
                          hls.attachMedia(video);
                          player.events.on('destroy', () => {
                              hls.stopLoad();
                              hls.destroy();
                          });
                      },
                  },
                }
            }
            this.dp.push(new DPlayer(option))
        })
      })
      
      this.pageParams = res._meta
      this.loading = false
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    showListModal(item) {
      this.modalData = item
      this.listVisible = true
    },
    hideListModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.listVisible = false
    },
    showDetailModal(item){
      this.modalData = item
      this.detail_visible = true
    },
    handleDetailOk(e) {
      this.detail_visible = false
    },
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要删除"${item.title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('notificationCenterDeleteAction', { data: { notify_id: item.notify_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>
<style lang="scss">
  .notiDetailModal{
    img{
      width: 100%;
    }
  }
  .message-bg{
      position: fixed;
      z-index: 4000;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.4);
  }
</style>